/* eslint-disable react/prop-types */
import { React } from 'react'
import Link from 'next/link'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import DnaLogoWithoutText from '../../../public/DnaLogoWithoutText.svg'

export default function Footer () {
  return <footer className="text-black-400 bg-gradient-to-l from-white to-white text-[11px] px-4">
    <div className="container-landing flex flex-col lg:flex-row items-center lg:items-end mb-2">
      <div className="w-full order-last lg:order-none lg:w-1/3">
        <div>
          <ul className="flex justify-between lg:justify-start">
            <li className="lg:pr-10 underline"><Link href="/terms-and-conditions">Terms and Conditions</Link></li>
            <li className="lg:pr-10 underline"><Link href="/privacy-policy" color="black">Privacy Policy</Link></li>
          </ul>
        </div>

      </div>
      <div className="flex flex-col lg:w-1/3 items-center justify-center">
        <div>
          <DnaLogoWithoutText height={25} width={100} viewBox={'0 0 100 22'} />
        </div>
        <div className='text-[10px]'>
          Database of Native Assets Ltd. 2023
        </div>

      </div>
      <nav className="pt-1 lg:pt-0 w-3/5 sm:w-1/3 text-center justify-center items-center lg:items-end lg:justify-end lg:text-right">
        <Link href="https://www.instagram.com/thednatech/ ">
          <a target="_blank"><Icon icon={faInstagram} className="text-xl mr-8 text-black" /></a>
        </Link>
        <Link href="https://www.linkedin.com/company/database-of-native-assets/about/?viewAsMember=true">
          <a target="_blank"><Icon icon={faLinkedin} className="text-xl mr-8 text-black" /></a>
        </Link>
        <Link href="https://www.youtube.com/channel/UCwGh_wVxWIYULvSKjOWeMOg">
          <a target="_blank"><Icon icon={faYoutube} className="text-xl mr-8 text-black" /></a>
        </Link>
        <Link href="https://twitter.com/thednatech">
          <a target="_blank"><Icon icon={faTwitter} className="text-xl text-black" /></a>
        </Link>
      </nav>
    </div>
  </footer>
}
