/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import { React, useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import Navigation from '../Navigation'
import MediaAsset from '../MediaAsset'
import Slider from '../Slider'
import Link from 'next/link'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import DnaLogo from '../../../public/DnaLogo.svg'
export default function Header (props) {
  const {
    featuredMedia,
    token,
    // artworkCode,
    navigationContainerRef,
    isHome,
    activeSection,
    navItems
  } = props

  const [scrolled, setScrolled] = useState(false)
  const authContext = useContext(AuthContext)

  useEffect(() => {
    window.onscroll = () => {
      if (document.scrollingElement.scrollTop > 0) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
  }, [])

  const scrollToSection = (section) => {
    if (navItems && navItems[section] && navItems[section].current) {
      const element = navItems[section].current
      const headerOffset = 90
      const elementPosition = element.getBoundingClientRect().top
      const bodyPosition = document.body.getBoundingClientRect().top
      const offsetPosition = elementPosition - bodyPosition - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }
  }

  function renderCarousel () {
    return (
      <div className="flex flex-col w-full">
        <div className="flex">
          <div className="flex items-center">
            <Icon icon={faAngleLeft}
              className={
                `prevBtn text-2xl md:text-3xl lg:text-4xl xl:text-5xl mr-1 md:mr-3 lg:mr-5
                cursor-pointer text-slate-300`
              }
            />
          </div>
          <Slider isLocalRender={false} token={token}
            attachmentList={token?.data[0].datum.attachments}
            featuredMedia={featuredMedia}
          />
          <div className="flex items-center">
            <Icon icon={faAngleRight}
              className={
                `nextBtn text-2xl md:text-3xl lg:text-4xl xl:text-5xl ml-1 md:ml-3
              lg:ml-5 cursor-pointer text-slate-300`
            }
            />
          </div>
        </div>
        <div className="slider-pagination flex justify-center mt-4">
        </div>
      </div>

    )
  }
  function renderBrandLogo () {
    if (token?.data[0].datum?.accessories?.brand_properties.brand_url) {
      return <Link href={`${token?.data[0].datum?.accessories?.brand_properties.brand_url}`}>
        <a target="_blank">
          <div className="max-w-[140px] md:max-w-[200px] md:w-8/12 lg:w-1/2 2xl:w-5/12 mx-auto mt-4 mb-2 flex justify-center">
            <img src={`${token?.data[0].datum?.accessories?.brand_properties.svg_logo}`} alt={`${token?.data[0].datum?.accessories?.brand_properties.name}`} />
          </div>
        </a>
      </Link>
    } else {
      return <div className="max-w-[140px] md:max-w-[200px] md:w-8/12 lg:w-1/2 2xl:w-5/12 mx-auto mt-4 mb-2 flex justify-center">
        <img src={`${token?.data[0].datum?.accessories?.brand_properties.svg_logo}`} alt={`${token?.data[0].datum?.accessories?.brand_properties.name}`} />
      </div>
    }
  }

  return (
    <header
      className={
        `bg-white z-20 sticky top-0 px-4 ${scrolled ? 'border-t-8 pb-4 border-white' : ''}`
      }
    >
      <div className="container-landing flex justify-between mt-4">
        <div className="flex w-1/3 items-center">
          <Link href="/">
            <a className="">
              <DnaLogo width="155" height="20" viewBox="0 0 155 20" />
            </a>
          </Link>
        </div>
        <div className="flex w-1/3 items-center justify-center text-sm font-light text-center ">
          <div className="hidden sm:block font-poppins text-black text-center justify-center">
            {isHome
              ? (
              <nav>
                <ul className="flex">
                  <li className="cursor-pointer">
                    <Link href="/">
                      <a
                        onClick={() => scrollToSection('learn')}
                        color={activeSection === 'learn' ? 'purple-300' : 'black'}
                        active={activeSection === 'learn' ? 'true' : 'false' }
                      >
                        Learn
                      </a>
                    </Link>

                  </li>
                  <div className="w-12"></div>
                  <li className="cursor-pointer">
                    <Link href="/build">
                      <a
                        // onClick={() => scrollToSection("build")}
                        color={activeSection === 'build' ? 'purple-300' : 'black'}
                        active={activeSection === 'build' ? 'true' : 'false'}
                      >
                        Build
                      </a>
                    </Link>
                  </li>
                  <div className="w-12"></div>
                  <li className="cursor-pointer">
                    <Link href="/partners">
                      <a
                        // onClick={() => scrollToSection("build")}
                        color={activeSection === 'partners' ? 'purple-300' : 'black'}
                        active={activeSection === 'partners' ? 'true' : 'false'}
                      >
                        Partners
                      </a>
                    </Link>
                  </li>
                  <div className="w-12"></div>
                  <li className="cursor-pointer">
                    <Link href="/media">
                      <a
                        // onClick={() => scrollToSection("media")}
                        color={activeSection === 'media' ? 'purple-300' : 'black'}
                        active={activeSection === 'media' ? 'true' : 'false'}
                      >
                        Media
                      </a>
                    </Link>
                  </li>
                </ul>
              </nav>)
              : null
            }
          </div>
        </div>
        <div className="flex w-1/3 text-right items-end justify-end">
          {authContext.user?.isLoggedIn
            ? (
            <div className="">
              <Navigation containerRef={navigationContainerRef} />
            </div>
              )
            : (
            <>
              <div className="sm:hidden">
                <Navigation containerRef={navigationContainerRef} />
              </div>
              <Link href="/sign-in">
                <a color="black" className="font-mono text-black hidden sm:block"> Sign in</a>
              </Link>
            </>

              )
          }
        </div>
      </div>
      {token?.data[0].datum?.accessories?.brand_properties && renderBrandLogo()}
      {featuredMedia && (
        <figure className="pt-3">
          <div className="w-full md:w-8/12 lg:w-1/2 2xl:w-5/12 mx-auto flex justify-center">
            { token?.data[0].datum.attachments === undefined ||
                Object.keys(token?.data[0].datum.attachments).length === 0
              ? (<MediaAsset
                alt={token?.data[0].datum.name}
                mime={token?.data[0].datum.accessories?.mime}
                src={featuredMedia}
                thumbnail_src={token?.data[0].datum.accessories?.hd_thumb}
                />)
              : (renderCarousel())}

            {
              // Temporary comment: the id tag should be revisited later with Levin to
              // decide if we redesign it and keep it or we dispose of it. A color code
              // could be use for rapid distintion between token types for instance.
              // <figcaption className="absolute bottom-1/2 right-1 text-2xl sm:text-4xl font-bold bg-brandPurple p-6 sm:p-8 transform origin-top-right -rotate-90">
              //   {artworkCode}
              // </figcaption>
            }
          </div>
        </figure>
      )}
    </header>
  )
}
