/* eslint-disable react/prop-types */
import { React, useEffect, useState, useRef, useCallback } from 'react'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
const pdfjsLib = import('pdfjs-dist/build/pdf')

export default function PdfViewer (props) {
  const { pdfUrl } = props
  const canvasRef = useRef()
  // pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const [pdfRef, setPdfRef] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [windowInnerSize, setWindowInnerSize] = useState({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    function handleResize () {
      setWindowInnerSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const renderPage = useCallback((pageNum, pdf = pdfRef) => {
    pdf && pdf.getPage(pageNum).then(function (page) {
      // Keep logs for now, we need to test for responsiveness a varied
      // assortment of PDF files with different aspect ratios plus we need to
      // test the resize of the window and the canvas after the PDF was rendered

      const originalPageViewport = page.getViewport({ scale: 1 })

      const ratioedWidth = windowInnerSize.width * 0.5
      const ratioedHeight = windowInnerSize.height * 0.5

      const ratioedWidthScale = ratioedWidth / ratioedHeight
      // const ratioedHeightScale = ratioedHeight / ratioedWidth

      const calculatedWidth = ratioedHeight * ratioedWidthScale
      // const calculatedHeight = ratioedWidth * ratioedHeightScale

      // TODO: Check whether to use the commented `finalRatio` formula or the currently active one:
      // - Commented finalRatio formula: Does not readjust the carousel's height when moving into a PDF file.
      // - Current finalRation formula: Adjusts the carousel's height to match the PDF's height based on the
      // max width available to render the PDF file.
      // const finalRatio = (calculatedWidth > calculatedHeight ? calculatedHeight / originalPageViewport.height : calculatedWidth / originalPageViewport.width)
      const finalRatio = calculatedWidth / originalPageViewport.width

      const finalPageViewport = page.getViewport({ scale: finalRatio })
      const canvas = canvasRef.current

      canvas.width = finalPageViewport.width
      canvas.height = finalPageViewport.height

      const renderContext = {
        canvasContext: canvas.getContext('2d'),
        viewport: finalPageViewport
      }
      page.render(renderContext)
    })
  }, [pdfRef, windowInnerSize])

  useEffect(() => {
    renderPage(currentPage, pdfRef)
  }, [pdfRef, currentPage, renderPage])

  useEffect(() => {
    pdfjsLib.then((pdfjs) => {
      pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
      const loadingTask = pdfjs.getDocument(pdfUrl)
      loadingTask.promise.then(loadedPdf => {
        // If there's a current canvas being rendered, destroy it and instead
        // proceed with the current request. Otherwise the render will fail.
        if (pdfRef) {
          pdfRef.destroy()
        }
        setPdfRef(loadedPdf)
      }, function (reason) {
        console.error(reason)
      })
    })
  }, [pdfUrl])

  const nextPage = () => pdfRef && currentPage < pdfRef.numPages && setCurrentPage(currentPage + 1)

  const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1)

  return (
      <div className='flex flex-col'>
        <div className="flex z-40 relative">
          <div className="flex-1 cursor-pointer hover:opacity-80" onClick={prevPage}>
            <Icon className="pt-1 text-4xl opacity-70" icon={faAngleLeft} />
          </div>
          <div className="flex-1 cursor-pointer hover:opacity-80 text-right" onClick={nextPage}>
            <Icon className="pt-1 text-4xl opacity-70" icon={faAngleRight} />
          </div>
        </div>
        <canvas className="w-full h-auto shadow-xl border-2 border-white border-opacity-40" ref={canvasRef}></canvas>
      </div>

  )
}
