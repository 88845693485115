/* eslint-disable react/prop-types */
import { React } from 'react'
import dynamic from 'next/dynamic'
import PdfViewer from '../PdfViewer'

export default function MediaAsset (props) {
  // ModelViewer component needs to be imported this way due to the model viewer
  // library loading in an asynchronous way and messing with the render.
  const ModelViewer = dynamic(
    () => import('../ModelViewer'),
    { ssr: false }
  )

  const { alt, mime, thumbnailSrc, src, productPage } = props

  function renderAsset () {
    if (src.includes('pdf')) {
      return (
        <PdfViewer pdfUrl={src} />
      )
    } else if (mime === 'model/gltf-binary') {
      return (
        <div className="aspect-w-1 aspect-h-1 w-full">
          <ModelViewer src={src}
            poster={thumbnailSrc || ''}
            alt={alt || ''}
            cssClass="object-cover"
            cameraControls
          />
        </div>
      )
    } else {
      return (
      // <div className="h-1/2">
          <img
            src={src}
            // className={`${productPage ? "h-[50vh] w-auto" : null } shadow-xl border-2 border-white border-opacity-40`}
            className={`${productPage ? 'xs:h-[35vh] sm:h-[40vh] lg:h-[50vh] xl:h-[45vh] 2xl:h-[60vh] object-scale-down' : null}`}
            alt={alt || ''}
          />
      // </div>

      )
    }
  }

  return (
    <>
      {renderAsset()}
    </>
  )
}
