/* eslint-disable react/prop-types */
import { React, useState, useContext } from 'react'
import { createPortal } from 'react-dom'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
// import Link from '../Link'
import Link from 'next/link'
import IconWithLabel from '../IconWithLabel'
import { AuthContext } from '../../context/AuthContext'
import { useRouter } from 'next/router'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
// import { useHistory } from "react-router-dom";

export default function Navigation (props) {
  const { containerRef } = props
  const [navOpen, setNavOpen] = useState(false)
  const toggleNav = () => setNavOpen(!navOpen)

  return (
    <>
      <IconWithLabel icon={faBars} onClick={toggleNav}>
      </IconWithLabel>
      <NavigationBody
        isOpen={navOpen}
        containerRef={containerRef}
        onClose={toggleNav}
      />
    </>
  )
}

function NavigationBody (props) {
  const { isOpen, containerRef, onClose } = props
  const auth = useContext(AuthContext)
  const router = useRouter()
  // let history = useHistory();

  const logout = async (e) => {
    e.stopPropagation()
    onClose()
    await auth.logout(() => router.push('/'))
    // window.location.reload()
  }
  const classes = `fixed z-20 w-[200px] py-12 top-3 right-3 bottom-3 bg-black transform transition-transform duration-75 ${
    isOpen
      ? 'shadow-2xl translate-x-0 opacity-100'
      : 'translate-x-full opacity-0'
  }`

  return containerRef.current
    ? createPortal(
        <nav className={classes}>
          <div className='flex text-start rounded-xl text-white py-2 px-6'>
            <Icon className='text-2xl cursor-pointer hover:opacity-80' icon={faTimes} onClick={onClose} />
          </div>
          {auth.user?.isLoggedIn
            ? (
            <ul className="mt-8 font-sans text-white text-center">
              <li className="py-6">
                <Link href="/my-collection">
                  <a color="white" className="text-xl font-semibold">
                    {' '}
                    My collection{' '}
                  </a>
                </Link>
              </li>
              {auth.user?.userType === 'commercial'
                ? (
                <li className="py-6">
                  <Link href="/digitise-asset">
                    <a color="white" className="text-xl font-semibold">
                      {' '}
                      Digitise Asset{' '}
                    </a>
                  </Link>
                </li>
                  )
                : (
                    null
                  )}

              <li className="py-6">
                <a onClick={logout} color="white" className="text-xl font-semibold">
                  {' '}
                  Sign Out{' '}
                </a>
              </li>
            </ul>
              )
            : (
            <ul className="mt-8 font-sans text-white text-center">
              <li className="py-6">
                <Link href="/" color="white" className="text-3xl">
                  <a color="white" className="text-xl font-semibold">
                    Home
                  </a>
                </Link>
              </li>
              <li className="py-6">
                <Link href="/build" color="white" className="text-3xl">
                  <a color="white" className="text-xl font-semibold">
                    Build
                  </a>
                </Link>
              </li>
              <li className="py-6">
                <Link href="/partners" color="white" className="text-3xl">
                  <a color="white" className="text-xl font-semibold">
                    Partners
                  </a>
                </Link>
              </li>
              <li className="py-6">
                <Link href="/media" color="white" className="text-3xl">
                  <a color="white" className="text-xl font-semibold">
                    Media
                  </a>
                </Link>
              </li>
              <li className="py-6">
                <Link href="/sign-in" color="white" className="text-3xl">
                  <a color="white" className="text-xl font-semibold">
                    Sign In
                  </a>
                </Link>
              </li>
            </ul>
              )}
        </nav>,
        containerRef.current
    )
    : null
}
