/* eslint-disable react/prop-types */
import { React } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-cube'
import 'swiper/css/pagination'

// import required modules
import { Navigation, Pagination } from 'swiper'

import MediaAsset from '../MediaAsset'

export default function Slider (props) {
  const { token, attachmentList, featuredMedia, isLocalRender } = props

  function renderMainAssetInCreateToken () {
    const mime = featuredMedia?.type !== '' ? featuredMedia?.type : 'model/gltf-binary'
    if (featuredMedia) {
      return (
        <SwiperSlide className="self-center">
          <MediaAsset
            // alt={token?.data[0].datum.name}
            mime={mime}
            src={`${URL.createObjectURL(featuredMedia)}#${mime}`}
            // thumbnail_src={token?.data[0].datum.accessories?.hd_thumb}
          />
        </SwiperSlide>
      )
    }
  }

  function renderAttachementsInCreateToken () {
    return attachmentList.map((object, index) => (
      <SwiperSlide key={index} className="self-center">
        <MediaAsset
          mime={null}
          src={`${URL.createObjectURL(object)}#${object.type}`}
          thumbnail_src={null}
          alt={null}
        />
      </SwiperSlide>
    ))
  }

  function renderMainAssetInProductPage () {
    return (
      <SwiperSlide className="self-center flex justify-center">
        <MediaAsset
          alt={token?.data[0].datum.name}
          mime={token?.data[0].datum.accessories?.mime}
          src={featuredMedia}
          thumbnail_src={token?.data[0].datum.accessories?.hd_thumb}
          productPage
        />
      </SwiperSlide>
    )
  }

  function renderAttachmentsInProductPage () {
    return Object.keys(attachmentList).map((key, index) => (
      <SwiperSlide key={index} className="self-center flex justify-center">
        <MediaAsset
          mime={attachmentList[index].mime}
          src={attachmentList[index].uri}
          thumbnail_src={attachmentList[index].hd_thumb}
          alt={attachmentList[index].caption}
          productPage
        />
      </SwiperSlide>
    ))
  }

  return (
    <Swiper
      // navigation={true}
      // modules={[Navigation, EffectCube, Pagination]}
      // className="mySwiper"
      // effect={"cube"}
      //   grabCursor={true}
      //   cubeEffect={{
      //     shadow: true,
      //     slideShadows: true,
      //     shadowOffset: 20,
      //     shadowScale: 0.94,
      //   }}
      //   pagination={true}
      // spaceBetween={50}
      // centeredSlides={true}
      // slidesPerView={1}
      // ***********CAROUSEL WITHOUT CUBE EFFECT***********
      // style={{
      //   "--swiper-navigation-color": "#fff",
      //   "--swiper-pagination-color": "#fff",
      // }}
      autoHeight={true}
      slidesPerView={1}
      centeredSlides={true}
      spaceBetween={20}
      navigation={{
        disabledClass: 'swipper-button-disabled',
        lockClass: 'swipper-button-lock',
        navigationDisabledClass: 'swipper-navigation-disabled',
        nextEl: '.nextBtn',
        prevEl: '.prevBtn'
      }}
      pagination={{
        clickable: true,
        el: '.slider-pagination',
        bulletActiveClass: 'swiper-pagination-bullet-active',
        bulletClass: 'swiper-pagination-bullet'

      }}
      // loop={true}
      modules={[Navigation, Pagination]}
      className="mySwiper"
      // ***********CAROUSEL WITHOUT CUBE EFFECT***********
    >
      {/* Render Main Asset */}
      {isLocalRender
        ? renderMainAssetInCreateToken()
        : renderMainAssetInProductPage()
      }
      {/* Render Attachments */}
      {isLocalRender
        ? renderAttachementsInCreateToken()
        : renderAttachmentsInProductPage()
      }
    </Swiper>
  )
}
