/* eslint-disable react/prop-types */
import { React } from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

export default function IconWithLabel ({ onClick, children, icon, alignIcon = 'right' }) {
  return <span className="flex text-black cursor-pointer hover:opacity-80" onClick={onClick}>
        {alignIcon === 'left' ? <Icon className="mr-5 text-3xl opacity-70" icon={icon} /> : null}
        {children}
        {alignIcon === 'right' ? <Icon className="ml-5 text-3xl opacity-70" icon={icon} /> : null}
    </span>
}
